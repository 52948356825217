.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-select__control {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 13px 15px;
}
.react-select__single-value {
  font-size: 16px;
  line-height: 20px;
  color: #121111;
}
.react-select__value-container {
  line-height: 20px;
}
.react-select__menu {
  background-color: #fff;
  padding: 12px;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.04);
  border-radius: 0 0 8px 8px;
}
.react-select__menu .react-select__option {
  margin-bottom: 6px;
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}
.slick-list {
  overflow: hidden;
}
.feed-carousel .slick-slider {
  margin: 0 -8px;
}
.feed-carousel .slick-slide > div {
  padding: 0 8px;
}
.slick-active > div {
  opacity: 1 !important;
}
.slick-dots li {
  width: 8px;
  height: 8px;
}
.slick-next {
  right: -12px;
}
.my-profile-feed .slick-next {
  right: 10px;
}

.my-profile-feed .slick-prev {
  left: 0px;
}

.slick-arrow {
  background: linear-gradient(
    89.13deg,
    rgba(34, 36, 51, 0.8) -6.79%,
    rgba(34, 36, 51, 0) 155.78%
  );
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background: linear-gradient(
    89.13deg,
    rgba(34, 36, 51, 0.8) -6.79%,
    rgba(34, 36, 51, 0) 155.78%
  );
}
.slick-prev:before,
.slick-next:before {
  content: url("./assets/images/icon_arrow_right.svg");
  opacity: 1;
  font-size: 0;
  line-height: 0;
}
.slick-prev {
  left: -12px;
  transform: translate(0, -50%) rotate(180deg);
}
.slick-disabled {
  display: none !important;
}
img {
  height: auto;
}

.web-nav-items li .bg-icon-color svg path {
  fill: #ffffff;
}

.button-submit {
  opacity: 0.5;
  cursor: default;
}

@media (max-width: 767px) {
  .feed-carousel .slick-slider {
    margin: 0;
  }
}
@media (max-width: 576px) {
  .guide-slider .slick-dots {
    margin: 0 auto;
  }
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url(./assets/images/icon_date.svg) no-repeat;
}
